$white-color: #fff;
$black-color: #000;
$gray-color: #808080;
$blue-color: #007bff;
$red-color: #e70014;
$gray-color2: #aaa;
$green-color2: #60ca3f;

//
.footer {
  display: flex;
  flex-wrap: wrap;
  //   justify-content: center;
  height: fit-content;
  color: $white-color;
  padding: 20px 20px 20px 50px;
  background-color: $black-color;

  &-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    &-title {
      display: block;
      width: 100%;
    }

    .mx-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-content {
    min-width: 245px;
    margin: 10px 30px 0 0;

    &-title {
      color: $red-color;
      border-bottom: 1px solid $red-color;
    }

    &>p {
      font-size: 0.8em;
      line-height: 1.5rem;
      margin-top: 15px;
    }

    &-social {
      display: block;
      text-align: center;
      color: $white-color;
      text-decoration: unset !important;
      margin: 10px;

      &:hover {
        color: $white-color;
      }

      >.line,
      >.facebook {
        width: 2.4rem;
        height: 2.4rem;
        margin-bottom: 2px;
        border-radius: 6px;
        margin-left: auto;
        margin-right: auto;
      }

      >.line {
        background-color: #00c300;

      }

      >.facebook {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #3e68c0;

        &>i {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .footer {
    &-content {
      min-width: calc(50% - 30px);
    }
  }
}

@media screen and (max-width: 580px) {
  .footer {
    &-content {
      min-width: calc(100% - 30px);
    }
  }
}